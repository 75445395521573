import React from "react";
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import Vision from "../components/Vision";
import Team from "../components/Team";
import Contact from "../components/Contact";
import MetaTags from "react-meta-tags";

const About = () => (
    <Layout>
        <MetaTags>
            <title>Incompli &raquo; About Us</title>
            <meta name="description" content="Trusted California stormwater Professionals. SWPPPs. QSP Services. Environmental Consulting. Take the stress out of compliance with Incompli."/>
        </MetaTags>
        <Hero background="/assets/images/about-hero-bg.jpg">
            <h1 className="text-white">Simplify Environmental Compliance</h1>
            <p className="text-white hero-text-block">Our mission at Incompli is to simplify environmental<br/> compliance with technology and efficiency.</p>
        </Hero>
        <Vision />
        <Team preview={false} />
        <Contact />
    </Layout>
);

export default About;