import React from "react";
import Layout from "../components/Layout";
import Banner from "../components/Banner";
import Hero from "../components/Hero";
import Spacer from "../components/Spacer";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";

const QualifiedSWPPPPractitioner = () => (
    <Layout>
        <MetaTags>
            <title>QSP SWPPP - Qualified SWPPP Practitioner</title> 
            <meta name="description" content="Ensure efficient SWPPP compliance with QSP-certified experts from Incompli. Contact us today for tailored solutions!" />
            <link rel="canonical" href="https://www.incompli.com/qualified-swppp-practitioner" />
                </MetaTags>
        <Hero background="/assets/images/services-hero-bg.jpg">
            <h1 className="text-white">Qualified SWPPP Practitioner (QSP) Services</h1>
            <p className="text-white" style={{ maxWidth: `780px`, margin: `auto` }}>Once the SWPPP has been developed, our team of Qualified SWPPP Practitioners (QSPs) will conduct all required SWPPP inspections and will identify and communicate issues that need to be addressed with site personnel to ensure they are fixed effectively and efficiently.</p>
        </Hero>
        <section id="additional-content" className="module">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-9 m-auto text-center">
                        <h2>What does a Qualified SWPPP Practitioner (QSP) do?</h2>
                        The main responsibility of a QSP is to oversee compliance of your SWPPP. This oversight is accomplished by performing regular site inspections to ensure the specified BMPs within your SWPPP are maintained. During inspections, the QSP will walk the project site to observe BMPs, sample discharge as needed, and provide guidance on any corrective actions. They will document findings. If any compliance issues are discovered, they will work with you to resolve issues in a timely manner. If revisions to the SWPPP are needed, they will suggest SWPPP updates to the QSD for amendments.
                    </div>
                </div>
            <Spacer my="30px" />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-9 m-auto text-center">
                        <h3>Summary of Incompli QSP Services</h3>
                    </div>
                </div>
                <Spacer my="30px" />
                <div className="row justify-content-center">
                    <div className="col-lg-11 m-auto text-center">
                        <ul className="arrow-list">
                                          <li>Oversee SWPPP compliance by performing site inspections</li>
                                          <li>Observe Best Management Practices (BMPs)</li>
                                          <li>Resolve SWPPP compliance issues by consulting with our team of experts</li>
                                        </ul>
                    </div>
                </div>
            </div>
            <Spacer my="30px" />
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-9 m-auto text-center">
                        <h4>Trust Incompli with QSP Needs</h4>
                        Due to our vast industry experience, our customers experience greater efficiencies within their projects. Our Qualified SWPPP Practitioners deliver superior customer service and offer key insights through cutting edge technology and software. Start your SWPPP journey today and experience peace of mind with Incompli.
                    </div>
                </div>
        </section>
        <Banner id="resources" image="/assets/images/our-vision.jpg">
            <h2>Resources</h2>
            <div>
                <a href="https://www.casqa.org/resources/qsp-qsd-qualification">CASQA QSP/QSD Qualification</a>
            </div>
        </Banner>
    </Layout>
);

export default QualifiedSWPPPPractitioner
